@import '../colors';

#copyright {
    display: block;
    margin: auto;
    margin-top: 1rem;
    font-size: 0.8rem;
    text-align: center;
    color: $gray-hhl;
    grid-column: 1 / -1;
}
