@import '../colors';

.tile {
    display: block;
    margin: 0;
    padding: 0;
    background-position: center;
    background-size: cover;
    transition: 0.2s;
    filter: grayscale(0.8);


    &:hover {
        filter: grayscale(0);
    }

    .overlay {
        padding: 1rem 1rem;
        color: $white;
        font-size: 1.3rem;
        font-weight: 300;
        text-align: right;
        height: 100%;
        box-sizing: border-box;
        background: rgba($black, 0.6);
    }
}

.tileGrid.code {
    .tile {
        background-position: top center;
    }
}
