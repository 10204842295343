@import '../colors';
@import '../App.scss';

#jsdoc {
    ul.flex {
        list-style-type: none;
        @extend .blockquote;
        font-size: 1.1rem;
        display: flex;
        flex-wrap: wrap;
        li {
            @extend .monospace;
            padding: 0.25rem 1rem;
            &:nth-child(2n + 1) {
                background: rgba($green, 0.2);
            }
        }
    }
    table {
        td {
            padding: 0.75rem 1.25rem;
            &:first-child {
                white-space: nowrap;
            }
            &:last-child {
                @extend .monospace;
                font-size: 1rem;
            }
        }
    }
}
