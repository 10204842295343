@import '../breakpoints';
@import '../colors';

.tileGrid {
    width: 100%;
    display: grid;
    grid-gap: 1rem;

    @media (min-width: $sm-md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $md-lg) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $lg-xl) {
        grid-template-columns: repeat(4, 1fr);
    }
    blockquote.commissions {
        font-size: 1.15rem;
        background: $white;
        align-self: stretch;
        margin: 0;
        font-weight: 400;
        border-color: $green;
        @media (min-width: $sm-md) {
            grid-column: 1 / -1;
        }
        @media (min-width: $lg-xl) {
            grid-column: 2 / span 2;
            grid-row: 2;
        }
        strong {
            font-weight: 600;
        }
    }

    #copyright {
        grid-column: 1 / -1;
    }
}

.dark .tileGrid {
    blockquote.commissions {
        background: $black;
    }
}