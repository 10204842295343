@import '../colors';
@import '../breakpoints';

#links {
    > .block {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        text-align: center;
    }

    img {
        width: 2rem;
        height: auto;
    }

    i {
        color: $black;
        font-size: 2rem;
    }

    img,
    i {
        margin-right: 1rem;
    }

    @media (min-width: $sm-md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        .block {
            flex-direction: column;
            margin: 0;
        }

        img,
        i {
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    @media (min-width: $md-lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $lg-xl) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.dark #links {
    .block {
        img {
            filter: invert(100%);
        }
    }
}