@import '../colors';
@import '../breakpoints';

#post {
    margin: auto;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    grid-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto auto;

    @media (min-width: $sm-md) {
        height: 100%;
    }

    > * {
        justify-self: center;
        align-self: flex-start;
    }

    &.art {
        @media (min-width: $sm-md) {
            grid-template-columns: auto 40%;
            grid-template-rows: auto 1fr auto;
            img {
                justify-self: center;
            }
        }
    }

    @media (min-width: $md-lg) {
        grid-template-columns: auto 50%;
        grid-template-rows: auto 1fr auto;
        img {
            justify-self: center;
        }
    }

    &.code {
        .script {
            display: none;
        }
        .cp_embed_wrapper {
            align-self: stretch;
            justify-self: stretch;
            iframe {
                height: 100%;
            }
        }
    }

    .caption {
        text-align: left;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        * {
            margin: 0 0 0.5rem 0;
        }
        .body {
            margin-bottom: 1rem;
        }

        .link,
        .github {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            i {
                color: $black;
                font-size: 2rem;
                margin: 0 0.25rem 0 0;
                vertical-align: middle;
            }
            img {
                height: 1.75rem;
                width: 1.75rem;
                margin: 0 0.5rem 0 0;
                vertical-align: bottom;
            }
        }
    }

    .back {
        grid-column: 1 / -1;
        justify-self: left;
        text-align: left;
        display: block;
    }

    .date {
        color: $gray;
    }

    #copyright {
        margin: 0;
    }
}

.dark #post {
    .caption {
        .date, .material-icons, img {
            filter: invert(100%);
        }
    }
}