$black-ll: #222;
$black: #333;
$green: #5fd3bb;
$green-ll: #1b9b81;
$gray: #666;
$gray-hl: #777;
$gray-hhl: #999;
$bg: #e7e7e7;
$bg-hl: #f0f0f0;
$bg-hhl: #f6f6f6;
$white: #fff;