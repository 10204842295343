@import 'breakpoints';
@import 'colors';

html,
body {
    font-family: 'Assistant', sans-serif;
    margin: auto;
    font-size: 16px;

    @media (min-width: $xs-sm) {
        font-size: 18px;
    }

    @media (min-width: $sm-md) {
        font-size: 20px;
    }

    ul,
    p {
        line-height: 1.55;
    }
}

.monospace {
    font-family: 'Source Code Pro', monospace;
}
pre {
    white-space: pre-wrap;
    @extend .monospace;
}

.blockquote {
    background: rgba($green, 0.15);
    padding: 1rem;
    font-weight: 300;
    font-size: 1.3rem;
    border-left: 1rem rgba($green, 0.5) solid;
    margin: 0;
    line-height: 1.45;
    padding-left: 1rem;
}
blockquote {
    @extend .blockquote;
}

table {
    border-collapse: collapse;
    font-size: 1.2rem;
    td {
        padding: 0.25rem 1rem;
    }
    tr:nth-child(2n + 1) {
        background: rgba($bg, 0.8);
    }
}

.material-icons {
    vertical-align: sub;
}

img {
    max-width: 100%;
    max-height: 100%;
}

#root > div {
    background: $bg;
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100vh;
    width: 100%;
    color: $black;
    &.dark {
        background: $black-ll;
        color: $white;
    }
}

.blog {
    .block {
        max-width: 40rem;
        margin: auto;
    }
}

.block {
    padding: 1rem;
    background: $bg-hhl;
}
.dark .block {
    background: $black;
}

a {
    color: $green-ll;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $green;
    }
}
.dark a {
    color: $green;

    &:hover {
        color: $green-ll;
    }
}

h1 {
    color: $gray-hhl;
    font-size: 1.4rem;
    font-weight: 100;
    text-align: center;
    text-transform: lowercase;
}

strong {
    font-weight: 700;
}
