@import '../colors';
@import '../breakpoints';

#achievements {
    @media (min-width: $sm-md) {
        grid-template-columns: 1fr 1fr;
        display: grid;
        grid-gap: 1rem;
        .achievement {
            margin: 0;
        }
    }

    h1 {
        grid-column: span 2;
        margin-top: 0;
    }

    .achievement {
        background: $bg-hhl;
        padding: 0.65rem 1rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 1rem;
        @media (min-width: $sm-md) {
            margin-bottom: 0;
        }

        i {
            margin-right: 1rem;
            margin-bottom: 0;
            box-sizing: border-box;
            text-align: center;
            background: $black;
            color: $green;
            height: 2.85rem;
            width: 2.85rem;
            padding: 0.4rem;
            border-radius: 3rem;
            font-size: 2rem;
            vertical-align: top;
            &.fake-icon {
                font-style: normal;
                padding: 0.05rem 0.25rem;
                &.small {
                    font-size: 1.8rem;
                }
            }
        }
        h3,
        p {
            margin: 0.35rem 0;
        }
    }
}

.dark #achievements {
    .achievement {
        background: $black;

        i {
            background: $green;
            color: $black;
        }
    }
}