@import '../colors';
@import '../breakpoints';

#home {
    font-size: 1.3rem;

    .block {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            margin: 0.5rem 0;
        }
    }

    #bio {
        grid-row: span 3;
        font-size: 1rem;
    }

    #copyright {
        margin: 0;
        display: block;
        grid-column: 1 / -1;
    }

    i {
        margin-bottom: 1rem;
        font-size: 2em;
        color: $black;
    }

    @media (min-width: $xs-sm) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

        .block {
            margin: 0;
            display: flex;
            &.wide {
                grid-column: span 2;
            }
        }
    }
    @media (min-width: $md-lg) {
        height: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.dark #home {
    i {
        color: $bg;
    }
}
