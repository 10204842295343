@import '../breakpoints';
@import '../colors';

#resume {
    .year,
    #green-border,
    h1 {
        display: none;
    }
    article {
        background: $bg-hhl;
        padding: 1rem;
        display: block;
        margin-bottom: 1rem;
        h3,
        h4 {
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
        h4 {
            color: rgba($gray, 0.8);
        }
        ul {
            padding-left: 1.25rem;
            li {
                font-size: 0.9rem;
            }
        }
        .tech {
            font-weight: 100;
            .tag {
                margin: 0.5rem 0.5rem 0 0;
                display: inline-block;
                padding: 0.25rem 0.5rem;
                background: rgba($bg, 0.8);
                font-size: 0.8rem;
            }
        }
    }
    @media (min-width: $md-lg) {
        display: grid;
        grid-template-columns: auto 0.5rem 1fr 1fr;
        grid-gap: 1rem;
        .year {
            grid-row: span 2;
            color: rgba($black, 0.4);
            grid-column: 1;
            font-size: 2rem;
            height: 5rem;
            margin-top: -3rem;
            display: flex;
            align-items: center;
        }
        #green-border {
            display: block;
            background: $green;
            grid-column-start: 2;
            grid-row: 2 / 23;
        }
        h1 {
            display: block;
        }
        article,
        h1 {
            margin: 0;
            grid-column: span 1;
            &.lane-1 {
                grid-column-start: 3;
            }
            &.lane-2 {
                grid-column-start: 4;
            }
            &.length-2 {
                grid-row: span 2;
            }
            &.length-3 {
                grid-row: span 3;
            }
            &.length-4 {
                grid-row: span 4;
            }
            &.length-5 {
                grid-row: span 5;
            }
            &.length-6 {
                grid-row: span 6;
            }
        }
        .align-self-start {
            align-self: start;
        }
        .align-self-center {
            align-self: center;
        }
        .align-self-end {
            align-self: end;
        }
        .placeholder {
            height: 10rem;
        }
    }
}

.dark #resume {
    article {
        background: $black;

        h4 {
            color: rgba($white, 0.5);
        }
        
        .tech .tag {
            background: rgba($gray, 0.6);
        }
    }
    .year {
        color: rgba($white, 0.5);
    }
}